import React, { useState } from "react";
import clsx from "clsx";
import { default as MUIDialog } from "@mui/material/Dialog";

import FrameImage1 from "../../assets/images/story/Troll_Promo_CityLife.webp";
import FrameImage2 from "../../assets/images/story/Troll_Promo_BurningCity.webp";
import FrameImage3 from "../../assets/images/story/Troll_Promo_HiringTrolls.webp";
import FrameImage4 from "../../assets/images/story/Troll_Promo_UrHired.webp";
import FrameImage5 from "../../assets/images/story/Troll_Promo_CompanyValues.webp";
import FrameImage6 from "../../assets/images/story/Troll_Promo_Cubicles.webp";
import FrameImage7 from "../../assets/images/story/Troll_Promo_CoolerTalk.webp";
import FrameImage8 from "../../assets/images/story/Troll_Promo_MemePosting.webp";
import FrameImage9 from "../../assets/images/story/Troll_Promo_TrollResources.webp";
import FrameImage10 from "../../assets/images/story/Troll_Promo_ITDepartment.webp";
import FrameImage11 from "../../assets/images/story/MicrosoftTeams-image.webp";
import FrameImage12 from "../../assets/images/story/MicrosoftTeams-image(1).webp";
import FrameImage13 from "../../assets/images/story/Troll_Promo_TrollOfMonth.webp";
import FrameImage14 from "../../assets/images/story/Troll_Promo_Finale.webp";
import FrameLockedImage from "../../assets/images/story/locked.webp";
import FrameLargeImage from "../../assets/images/story/Frame_Large.webp";

import Styles from "./index.module.scss";

const Dialog = ({ onClose, open, item }) => {
  const comingSoon = "Tony Shall Unlock Soon";
  return (
    <MUIDialog open={open} className={Styles.story_dialog} onClose={onClose}>
      <div className={Styles.dialog_inner}>
        <img
          alt="frame_large"
          src={FrameLargeImage}
          className={Styles.frame_large}
        />
        {item.reveal ? (
          <img alt="frame" className={Styles.frame_image} src={item.image} />
        ) : (
          <img
            alt="frame"
            className={Styles.frame_image}
            src={FrameLockedImage}
          />
        )}
        <div className={Styles.description}>
          {item.reveal
            ? item.text?.split("\n").map((line, index) => (
                <label key={index}>
                  {line}
                  <br />
                </label>
              ))
            : comingSoon}
        </div>
      </div>
    </MUIDialog>
  );
};

const Frame = ({ item, setIsDialogOpened, setSelectedFrame }) => {
  const clickFrame = () => {
    setIsDialogOpened(true);
    setSelectedFrame(item);
  };
  return (
    <div className={clsx(Styles.frame_wrap, item.className)}>
      <div onClick={clickFrame} className={clsx(Styles.frame)}>
        {item.reveal ? (
          <img alt="frame" src={item.image} />
        ) : (
          <img alt="frame" src={FrameLockedImage} />
        )}
      </div>
    </div>
  );
};

const StroySection = ({ initialLoad }) => {
  const frames = [
    {
      image: FrameImage1,
      className: Styles.frame1,
      reveal: true,
      text: "The day of reckoning is upon us. Society is on the brink of collapse, the trolls are widespread and distrought trolling around without purpose. The world as we know it is facing collapse.",
    },
    {
      image: FrameImage2,
      className: Styles.frame2,
      reveal: true,
      text: "But fret not, my dear friends, for Troll Inc. is here to make amends, we've got beautiful views and food trucks galore, plus medical insurance for radiation burns and more! At troll inc we welcome trolls of all kinds just ace our application and let the good times unwind.",
    },
    {
      image: FrameImage3,
      reveal: true,
      className: Styles.frame3,
      text: "At Troll Inc., we welcome trolls of all kinds, just ace our application and let the good times unwind! Trust us with your life, run it like we're your guiding star, obedience leads to success, laughter is our language, no matter who you are! And remember, you signed a contract, so stay with us and become a shining star!",
    },
    {
      image: FrameImage4,
      reveal: true,
      className: Styles.frame4,
      text: "Now let's go on a tour and explore, watch out for the flame throwers and toxic waste, sales is 100% safe, even if there's a troll lying face down like a disgrace! Keep in mind, outsiders will try and scare you and FUD you into submission. Trust your fellow trolls and you will achieve your wildest dreams here at Troll Inc",
    },
    {
      image: FrameImage5,
      className: Styles.frame5,
      reveal: true,
      text: "These are Troll Inc’s proud company values: \nT: Trust Troll Inc. \nR: Run your life freely as as the troll you are\nO: Open the door of Troll Inc to you friends\nL: Laughter is our language\nL: Like it or not,Troll Inc will never die\nLet’s begin the tour!",
    },
    {
      image: FrameImage6,
      className: Styles.frame6,
      reveal: true,
      text: "Here is where you shall work, we have a relaxed code of conduct that allows each troll to express themselves and be comfortable within their office enviornment. They are all doing their job which is spreading the troll culture and being good internet trolls. Keep in mind, while we have fun, we never are outright mean. Clever trolls thrive the most here and are rewarded accordingly :)",
    },
    {
      image: FrameImage7,
      className: Styles.frame7,
      reveal: true,
      text: "Take a break by the water cooler, just 30 seconds to cool down and regain your power, get ideas from your fellow trolls in the telegram and learn how to be a better troll.",
    },
    {
      image: FrameImage8,
      className: Styles.frame8,
      reveal: true,
      text: "While we do allow Trolls to take their time to unwind we do still value our time as a business. So if you are caught stealing company time away by oh, say, watching Troll Hub on your computer, like these two gentlemen…",
    },
    {
      image: FrameImage9,
      className: Styles.frame9,
      reveal: true,
      text: "Meet Tina, our Troll Resources expert, she'll solve all your problems and calm your nerves, she is a good telegram moderator and can always give you piece of mind and new ideas on how to share the troll culture.",
    },
    {
      image: FrameImage10,
      className: Styles.frame10,
      reveal: true,
      text: "There will always be high stress moments here at Troll Inc. This is our IT department who focus on putting Troll Inc. on the map. Sometimes people try and bring them down and it certainly gets hot with all of the electricity in there, but they always perservere and keep the place running smoothly!",
    },
    {
      image: FrameImage11,
      className: Styles.frame11,
      reveal: false,
      text: "Here is our hi tech R&D department! At Troll Inc. we strive for innovation above all else. Just look at that jetpack! I smell an industry award! Yes it does also smell like burnt flesh and engine grease, but just the cost of doing business! Also, those who surpass expectations and bring new innovation are always awarded very well.",
    },
    {
      image: FrameImage12,
      className: Styles.frame12,
      reveal: false,
      text: "Behold the CEO of Troll Inc. Tony Troll! For years he has given trolls the means to put food on the table, he started the whole revolution. Tony is the best of us and his time is very valuble. Always be respectful if you do see him you do not want to get on his bad side.",
    },
    {
      image: FrameImage13,
      className: Styles.frame13,
      reveal: false,
      text: "Here we have the Troll of the Month award, our highest honor! Even YOU can achieve this distinguished accolade. Just remember the Company Values, keep yourself from getting a concussion, and the chances of having your photo on this wall will greatly increase! The troll of the month recieves quite the bonus pay as well ;)",
    },
    {
      image: FrameImage14,
      className: Styles.frame14,
      reveal: false,
      text: "Keep our values in mind, be bold and wear your helmet with pride, steer clear of the chatty one or you'll be escorted out like an unwelcome bride. But above all, have fun and embrace the Troll Inc. culture, where we troll and triumph like no other!",
    },
  ];
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [selectedFrame, setSelectedFrame] = useState({});
  const onClose = () => {
    setIsDialogOpened(false);
  };
  return (
    <>
      <div
        className={clsx(Styles.story_section, {
          [Styles.music_started]: !initialLoad,
        })}
      >
        <div className={Styles.blur}></div>
        <div className={Styles.frames}>
          {frames.map((item, index) => (
            <Frame
              item={item}
              setIsDialogOpened={setIsDialogOpened}
              setSelectedFrame={setSelectedFrame}
              key={index}
            />
          ))}
        </div>
      </div>
      <Dialog open={isDialogOpened} item={selectedFrame} onClose={onClose} />
    </>
  );
};

export default StroySection;
