import { useTick } from "@inlet/react-pixi";
import { useState } from "react";

export default function useAnimateTick(func, limit = 100, delay = 0) {
  const [direct, setDirect] = useState(1);
  const [it, setIt] = useState(0);

  useTick((delta) => {
    const temp = it + direct * delta;
    if ((temp > limit && direct === 1) || (temp < 0 && direct === -1)) {
      setDirect(-direct);
    }
    func(temp);
    setIt(temp);
  });
}
