import React, { useEffect, useState } from "react";
import useSound from "use-sound";
import { Container, Sprite, Stage } from "@inlet/react-pixi";
import clsx from "clsx";
import { Scrollbars } from "react-custom-scrollbars";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MusicOffIcon from "@mui/icons-material/MusicOff";

import useAnimateTick from "./useAnimateTick";
import useLineAnimationTick from "./useLineAnimationTick";
import StroySection from "./components/StorySection";

import GroundImage from "./assets/images/home/BG_Ground.webp";
import SkyImage from "./assets/images/home/BG_Sky_Light.webp";
import BuringCityImage from "./assets/images/home/BurningCity.webp";
import BuringCityGlowImage from "./assets/images/home/BurningCity_Glow.webp";
import Billboard1Image from "./assets/images/home/Billboard_1.webp";
import Billboard1LightGlowImage from "./assets/images/home/Billboard_1_LightGlow.webp";
import Billboard2Image from "./assets/images/home/Billboard_2.webp";
import BillboardMobile2Image from "./assets/images/home/mobile/billboard2.webp";
import Billboard2LightGlowImage from "./assets/images/home/Billboard_2_LightGlow.webp";
import MoonImage from "./assets/images/home/Moon.webp";
import MoonMobileImage from "./assets/images/home/mobile/moon.webp";
import BridgeBackImage from "./assets/images/home/Bridge_Back.webp";
import BridgeFrontImage from "./assets/images/home/Bridge_Front.webp";
import BuildingImage from "./assets/images/home/Building.webp";
import BuildingMobileImage from "./assets/images/home/mobile/building.webp";
import Tree1Image from "./assets/images/home/Tree_1.webp";
import Tree2Image from "./assets/images/home/Tree_2.webp";
import RocketImage from "./assets/images/home/Rocket.webp";
import RocketMobileImage from "./assets/images/home/mobile/rocket.webp";
import NuclearwasteImage from "./assets/images/home/Nuclearwaste.webp";
import NuclearwasteGlowImage from "./assets/images/home/Nuclearwaste_Glow.webp";
import TrollHead2Image from "./assets/images/home/Troll_Head_2.webp";
import TrollLegs2Image from "./assets/images/home/Troll_Legs_2.webp";
import TrollArm2Image from "./assets/images/home/Troll_Arm_2.webp";
import TrollBody2Image from "./assets/images/home/Troll_Body_2.webp";
import TrollSign2Image from "./assets/images/home/Troll_Sign_2.webp";
import TrollUmbrellaImage from "./assets/images/home/Umbrella.webp";
import TrollUmbrellaMobileImage from "./assets/images/home/mobile/troll_umbrella.webp";
import TrollPicnicImage from "./assets/images/home/Picnic.webp";
import TrollArm1Image from "./assets/images/home/Troll_Arm_1.webp";
import TrollBody1Image from "./assets/images/home/Troll_Body_1.webp";
import TrollHead1Image from "./assets/images/home/Troll_Head_1.webp";
import TrollLeg1Image from "./assets/images/home/Troll_Leg_1.webp";
import TrollTireSwingImage from "./assets/images/home/Troll_TireSwing.webp";
import SunImage from "./assets/images/home/Sun.webp";
import WorkTroll1Image from "./assets/images/home/WorkTroll_1.webp";
import WorkTroll2Image from "./assets/images/home/WorkTroll_2.webp";
import WorkTroll3Image from "./assets/images/home/WorkTroll_3.webp";
import WorkTroll4Image from "./assets/images/home/WorkTroll_4.webp";
import WorkTroll5Image from "./assets/images/home/WorkTroll_5.webp";
import WorkTroll6Image from "./assets/images/home/WorkTroll_6.webp";
import WorkTroll7Image from "./assets/images/home/WorkTroll_7.webp";
import TrollPeeingImage from "./assets/images/home/Troll_Peeing.webp";
import TrollDivingboardImage from "./assets/images/home/Troll_Divingboard.webp";
import ChimneyImage from "./assets/images/home/Chimney.webp";
import GrassMobileImage from "./assets/images/home/mobile/grass.webp";
import TelegramMobilImage from "./assets/images/home/mobile/telegram_icon.webp";
import TwitterMobileImage from "./assets/images/home/mobile/twitter_icon.webp";
import TrollMintMobileImage from "./assets/images/home/mobile/troll_mint.webp";
import BuyMobileImage from "./assets/images/home/mobile/buy.webp";
import GroundMobileImage from "./assets/images/home/mobile/BG_Ground.webp";
import SkyMobileImage from "./assets/images/home/mobile/sky_bg.webp";
import BillBoardMobile1Image from "./assets/images/home/mobile/billboard1.webp";

import mySound from "./assets/music/background_music.mp3";

import styles from "./App.module.scss";

const BurningCity = () => {
  const [x, setX] = useState(1);
  const [y, setY] = useState(1);
  const [alpha, setAlpha] = useState(1);
  const trickFunc = (i) => {
    setX(1 + i * 0.05 * 0.005);
    setY(1 + i * 0.1 * 0.005);
    setAlpha(i * 0.004 + 0.5);
  };
  useAnimateTick(trickFunc);

  return (
    <>
      <Sprite
        image={BuringCityImage}
        x={808}
        y={1048}
        scale={{ x, y }}
        anchor={{ x: 1, y: 1 }}
      />
      <Sprite
        image={BuringCityGlowImage}
        x={1117}
        y={1283}
        alpha={alpha}
        scale={{ x, y }}
        anchor={{ x: 1, y: 1 }}
      />
    </>
  );
};

const Rocket = ({ xPosition, yPostion, image }) => {
  const [x, setX] = useState(1);
  const [y, setY] = useState(1);
  const trickFunc = (i) => {
    setX(1 + i * 0.02 * 0.005);
    setY(1 + i * 0.14 * 0.005);
  };
  useAnimateTick(trickFunc);

  return (
    <>
      <Sprite
        image={image}
        x={xPosition}
        y={yPostion}
        scale={{ x, y }}
        anchor={{ x: 1, y: 1 }}
      />
    </>
  );
};

const Nuclearwaste = () => {
  const [alpha, setAlpha] = useState(1);
  const [scale, setScale] = useState(1);
  const trickFunc = (i) => {
    setAlpha(i * 0.004 + 0.6);
    setScale(i * 0.0005 + 1);
  };

  useAnimateTick(trickFunc, 120);
  return (
    <>
      <Sprite image={NuclearwasteImage} x={1294} y={1060} />
      <Sprite
        image={NuclearwasteGlowImage}
        x={1294}
        y={1000}
        alpha={alpha}
        scale={scale}
      />
    </>
  );
};

const Billboard1 = ({ x1, y1, x3, y3, style, image, rotation, isMobile }) => {
  const [alpha, setAlpha] = useState(1);
  const trickFunc = (i) => {
    setAlpha(i * 0.004 + 0.2);
  };

  useAnimateTick(trickFunc, 120);
  return (
    <>
      <Sprite
        image={image}
        className={styles.text_wrap_image}
        x={x1}
        y={y1}
      ></Sprite>
      {!isMobile ? (
        <Sprite image={Billboard1LightGlowImage} x={x3} y={y3} alpha={alpha} />
      ) : (
        <></>
      )}
    </>
  );
};

const Billboard2 = ({ x1, y1, x2, y2, image, isMobile }) => {
  const [alpha, setAlpha] = useState(1);
  const trickFunc = (i) => {
    setAlpha(i * 0.004 + 0.2);
  };

  useAnimateTick(trickFunc, 120);

  return (
    <>
      <Sprite image={image} x={x1} y={y1} />
      {!isMobile ? (
        <Sprite image={Billboard2LightGlowImage} x={x2} y={y2} alpha={alpha} />
      ) : (
        <></>
      )}
    </>
  );
};

const Moon = ({ x, y, image }) => {
  const [degree, setDegree] = useState(1);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const trickFunc = (i) => {
    setScaleX(i * 0.0005 + 1);
    setScaleY(i * 0.001 + 1);
    setDegree(i * 0.01 + 50);
  };

  useAnimateTick(trickFunc, 100);

  return (
    <>
      <Sprite
        image={image}
        x={x}
        y={y}
        scale={{ x: scaleX, y: scaleY }}
        rotation={degree}
        anchor={0.5}
      />
    </>
  );
};

const Building = () => {
  const [degree, setDegree] = useState(1);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);

  const trickFunc = (i) => {
    setDegree(i * 0.05 - 10);
  };

  const trick2Func = (i) => {
    setScaleX(i * 0.0002 + 1);
    setScaleY(i * 0.0001 + 1);
  };

  useAnimateTick(trickFunc, 100);
  useAnimateTick(trick2Func, 80);

  return (
    <Container
      scale={{ x: scaleX, y: scaleY }}
      angle={degree}
      pivot={[1000, 900]}
      position={[1370, 870]}
    >
      <TrollDivingboard />
      <Chimney />
      <Sprite image={BuildingImage} x={965} y={890} anchor={{ x: 0.5, y: 1 }} />
      <TrollPeeing />
    </Container>
  );
};

const TrollMint = ({ handleMintClick }) => {
  const [degree, setDegree] = useState(1);
  const [bDegree, setBDegree] = useState(1);
  const [hDegree, setHDegree] = useState(1);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const trickFunc = (i) => {
    setScaleX(i * 0.0002 + 1);
    setScaleY(i * 0.0004 + 1);
    setDegree(i * 0.005 + 50);
    setBDegree(i * 0.001);
    setHDegree(i * 0.002);
  };

  useAnimateTick(trickFunc, 100);

  return (
    <>
      <Sprite
        interactive
        pointerdown={handleMintClick}
        image={TrollLegs2Image}
        x={2025}
        y={1200}
        anchor={1}
      />
      <Sprite
        interactive
        pointerdown={handleMintClick}
        image={TrollSign2Image}
        x={1975}
        y={1070}
        anchor={1}
        rotation={degree}
      />
      <Sprite
        interactive
        pointerdown={handleMintClick}
        image={TrollBody2Image}
        x={2045}
        y={1130}
        anchor={1}
        scale={{ x: scaleX, y: scaleY }}
        rotation={bDegree}
      />
      <Sprite
        interactive
        pointerdown={handleMintClick}
        image={TrollArm2Image}
        x={1980}
        y={1025}
        anchor={0}
        rotation={hDegree}
      />
      <Sprite
        interactive
        pointerdown={handleMintClick}
        image={TrollHead2Image}
        x={1965}
        y={1035}
        anchor={{ x: 0.5, y: 1 }}
        rotation={hDegree}
      />
    </>
  );
};

const TrollUmbrella = ({ x1, y1, x2, y2, x3, y3, x4, y4, x5, y5, x6, y6 }) => {
  const [degree, setDegree] = useState(1);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const [hDegree, setHDegree] = useState(1);
  const [aDegree, setADegree] = useState(1);
  const trickFunc = (i) => {
    setScaleX(i * 0.0002 + 1);
    setScaleY(i * 0.0004 + 1);
    setDegree(i * 0.0025 + 50.15);
  };

  const trick2Func = (i) => {
    setHDegree(i * 0.002);
    setADegree(i * 0.006 + 49.8);
  };

  useAnimateTick(trickFunc, 100);
  useAnimateTick(trick2Func, 120);

  return (
    <>
      <Sprite image={TrollPicnicImage} x={x1} y={y1} anchor={1} />
      <Sprite
        image={TrollArm1Image}
        x={x2}
        y={y2}
        anchor={{ x: 0, y: 1 }}
        rotation={aDegree}
      />
      <Sprite
        image={TrollBody1Image}
        x={x3}
        y={y3}
        anchor={1}
        scale={{ x: scaleX, y: scaleY }}
      />
      <Sprite image={TrollLeg1Image} x={x4} y={y4} anchor={1} />
      <Sprite
        image={TrollHead1Image}
        x={x5}
        y={y5}
        anchor={{ x: 0.5, y: 1 }}
        rotation={hDegree}
      />
      <Sprite
        image={TrollUmbrellaImage}
        x={x6}
        y={y6}
        anchor={{ x: 0.7, y: 1 }}
        rotation={degree}
      />
    </>
  );
};

const TrollTireSwing = () => {
  const [degree, setDegree] = useState(1);

  const trickFunc = (i) => {
    setDegree(i * 0.025 - 0.8);
  };

  useAnimateTick(trickFunc, 70);

  return (
    <>
      <Sprite
        image={TrollTireSwingImage}
        x={1595}
        y={796}
        anchor={{ x: 0.25, y: 0 }}
        rotation={degree}
      />
    </>
  );
};

const TrollPeeing = () => {
  const [degree, setDegree] = useState(1);

  const trickFunc = (i) => {
    setDegree(i * 0.0025);
  };

  useAnimateTick(trickFunc, 70);

  return (
    <Sprite
      image={TrollPeeingImage}
      x={690}
      y={655}
      anchor={{ x: 0.6, y: 0.1 }}
      rotation={degree}
    />
  );
};

const Chimney = () => {
  const [degree, setDegree] = useState(1);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);

  const trick2Func = (i) => {
    setScaleX(i * 0.0002 + 1);
    setScaleY(i * 0.003 + 1);
  };

  const trickFunc = (i) => {
    setDegree(i * 0.0025);
  };

  useAnimateTick(trickFunc, 70);
  useAnimateTick(trick2Func, 100);

  return (
    <Sprite
      image={ChimneyImage}
      x={668}
      y={405}
      scale={{ x: scaleX, y: scaleY }}
      anchor={{ x: 0.6, y: 1 }}
      rotation={degree}
    />
  );
};

const TrollDivingboard = () => {
  const [degree, setDegree] = useState(1);

  const trickFunc = (i) => {
    setDegree(i * 0.003 - 0.3);
  };

  useAnimateTick(trickFunc, 120);

  return (
    <Sprite
      image={TrollDivingboardImage}
      x={1250}
      y={520}
      anchor={{ x: 0, y: 1 }}
      rotation={degree}
    />
  );
};

const Sun = () => {
  const [degree, setDegree] = useState(1);
  const [x, setX] = useState(1025);
  const [y, setY] = useState(620);

  const trickFunc = (i) => {
    setDegree(i * 0.008 - 0.5);
  };

  const trick2Func = (i) => {
    setX(x - (30 - Math.floor(i)) * 0.03);
    setY(y + (30 - Math.floor(i)) * 0.005);
  };

  useAnimateTick(trickFunc, 100);
  useAnimateTick(trick2Func, 60);

  return (
    <>
      <Sprite image={SunImage} x={x} y={y} rotation={degree} anchor={0.5} />
    </>
  );
};

const WorkTroll = ({
  image,
  fx,
  fy,
  lx,
  ly,
  sx,
  sy,
  start = 0,
  end = 0,
  limit = 100,
}) => {
  const [degree, setDegree] = useState(start);
  const [x, setX] = useState(fx);
  const [y, setY] = useState(fy);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const trickFunc = (i) => {
    setDegree(((end - start) * i) / limit + start);
  };
  const trickLineFunc = (i) => {
    setX(fx + ((lx - fx) * i) / limit);
    setY(fy + ((ly - fy) * i) / limit);
    setScaleX(1 + ((sx - 1) * i) / limit);
    setScaleY(1 + ((sy - 1) * i) / limit);
  };

  useLineAnimationTick(trickLineFunc, limit);
  useLineAnimationTick(trickFunc, limit);

  return (
    <Sprite
      image={image}
      anchor={0.5}
      x={x}
      y={y}
      angle={degree}
      scale={{ x: scaleX, y: scaleY }}
    />
  );
};

const WorkTrolls = () => {
  return (
    <>
      <WorkTroll
        image={WorkTroll7Image}
        fx={605}
        fy={1030}
        lx={645}
        ly={1030}
        sx={33 / 25}
        sy={24 / 17}
        start={0}
        end={30}
      />
      <WorkTroll
        image={WorkTroll6Image}
        fx={645}
        fy={1030}
        lx={685}
        ly={1040}
        sx={50 / 33}
        sy={39 / 24}
        start={30}
        end={60}
      />
      <WorkTroll
        image={WorkTroll5Image}
        fx={685}
        fy={1040}
        lx={745}
        ly={1050}
        sx={80 / 50}
        sy={70 / 39}
        start={60}
        end={40}
      />
      <WorkTroll
        image={WorkTroll4Image}
        fx={745}
        fy={1050}
        lx={845}
        ly={1020}
        sx={94 / 80}
        sy={82 / 70}
        start={50}
        end={-50}
      />
      <WorkTroll
        image={WorkTroll3Image}
        fx={845}
        fy={1020}
        lx={965}
        ly={980}
        sx={93 / 94}
        sy={84 / 82}
        start={-50}
        end={0}
      />
      <WorkTroll
        image={WorkTroll2Image}
        fx={965}
        fy={980}
        lx={1085}
        ly={920}
        sx={96 / 93}
        sy={87 / 84}
        start={0}
        end={70}
      />
      <WorkTroll
        image={WorkTroll1Image}
        fx={1085}
        fy={920}
        lx={1255}
        ly={800}
        sx={1}
        sy={1}
        start={70}
        end={-70}
      />
    </>
  );
};

const App = () => {
  const [width, setWidth] = useState(1920);
  const [height, setHeight] = useState(1300);
  const [playSound, { stop }] = useSound(mySound, { loop: true });
  const [soundPlayed, setSoundPlayed] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const thumbStyle = {
    backgroundColor: "white",
    width: "5px",
    borderRadius: "10px",
  };
  const checkWidth = () => {
    if (window.innerWidth < 720) {
      setWidth(window.innerWidth);
      setHeight(window.innerWidth * 4.188);
      setIsMobile(true);
    } else if (window.innerWidth < 980) {
      setWidth(window.innerWidth - 50);
      setHeight((window.innerWidth - 50) * 0.793);
      setIsMobile(false);
    } else if (window.innerWidth < 1280) {
      setWidth(window.innerWidth - 100);
      setHeight((window.innerWidth - 100) * 0.793);
      setIsMobile(false);
    } else {
      setWidth(window.innerWidth * 0.9);
      setHeight(window.innerWidth * 0.9 * 0.793);
      setIsMobile(false);
    }
  };
  useEffect(() => {
    checkWidth();
  }, []);
  window.addEventListener("resize", checkWidth);
  const handleClick = () => {
    setInitialLoad(false);
    playSound();
    setSoundPlayed(true);
  };
  const handleMusicButtonClick = () => {
    if (!soundPlayed) playSound();
    else stop();
    setSoundPlayed(!soundPlayed);
  };
  const handleStageMount = (app) => {
    const renderer = app.renderer;
    renderer.plugins.interaction.autoPreventDefault = false;
    renderer.view.style.touchAction = "auto";
  };
  const handleMintClick = () => {
    window.open(
      ""
    );
  };
  const handletwitterClick = () => {
    window.open("https://twitter.com/Trollsinc_eth");
  };
  const handleTelegramClick = () => {
    window.open("https://t.me/trollsinc_eth");
  };

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.audio_wrap, {
          [styles.music_started]: !initialLoad,
        })}
      >
        <button className={styles.music_start} onClick={() => handleClick()}>
          Enter
        </button>
      </div>

      <div
        className={clsx(styles.welcome, {
          [styles.music_started]: !initialLoad,
        })}
      >
        <button
          className={styles.music_button}
          onClick={handleMusicButtonClick}
        >
          {soundPlayed ? <MusicOffIcon /> : <MusicNoteIcon />}
        </button>
        <Scrollbars
          className={styles.scrollable}
          renderThumbVertical={({ style, ...props }) => (
            <div {...props} style={{ ...style, ...thumbStyle }} />
          )}
        >
          <p>
            {
              "Welcome to the wacky world of Troll Inc - the ultimate destination for internet trolls! Join our underground community internet trolls with $TROLLS and become a part of the strongest and most hilarious trolling community on the internet. Our animated story at the bottom will explain how we have come to be. At Troll Inc, we don't just talk trash, we give the world of shitcoins a voice! Buy the $TROLLS token and claim your rightful place as a member of our exclusive community. With a troll toll of only 1% buy and sell fee, you can fuel the dao that is Troll Inc. The $TROLLS token is the key to everything. 7% of the total supply is kept by the owner wallet and only for use by CEX's or partnerships, 3% for the team. You will also be able to mint a troll nft with your $TROLLS token soon after launch. All tokens used to mint will be burned, and any NFTs minted with ETH will be sent directly to the dao fund. Don't be a troll under a bridge, join Troll Inc now and become a part of the ultimate trolling adventure!"
            }
          </p>
        </Scrollbars>
        <Scrollbars
          className={styles.small_scrollable}
          renderThumbVertical={({ style, ...props }) => (
            <div {...props} style={{ ...style, ...thumbStyle }} />
          )}
        >
          <p>
            {
              "Contract:\n0x00000000\n0000000000\n000000000\n000000000\n Trollonomics:\n - 90% to Liquidity pool\n - 7% Used for Partnerhsips and CEX's\n - 3% Team Allocation\n - 1% Fee on all Transactions for DAO and Initial Marketing "
            }
          </p>
        </Scrollbars>
        {!isMobile ? (
          <Stage
            width={width}
            height={height}
            options={{
              backgroundAlpha: 0,
              antialias: true,
            }}
            className={styles.desktop_canvas}
            onMount={handleStageMount}
          >
            <Sprite
              interactive
              buttonMode
              image={SkyImage}
              width={width}
              height={height}
            >
              <Sprite image={GroundImage} x={173.5} y={851} />
              <BurningCity />
              <Moon x={1875} y={325} image={MoonImage} />
              <Sun />
              <Billboard1
                x1={335}
                y1={275}
                x3={315}
                y3={225}
                rotation={50.1}
                style={{ fontSize: "30px", wordWrap: true, wordWrapWidth: 350 }}
                image={Billboard1Image}
                isMobile={false}
              />
              <Billboard2
                x1={1845}
                y1={365}
                x2={1765}
                y2={345}
                image={Billboard2Image}
                isMobile={false}
              />
              <Sprite image={Tree1Image} x={975} y={650} />
              <Sprite image={Tree2Image} x={1475} y={670} />
              <Sprite image={BridgeBackImage} x={725} y={751} />
              <WorkTrolls />
              <Building />
              <Sprite image={BridgeFrontImage} x={665} y={775} />
              <Rocket xPosition={1888} yPostion={920} image={RocketImage} />
              <Nuclearwaste />
              <TrollMint handleMintClick={handleMintClick} />
              <TrollUmbrella
                x1={1153}
                y1={1280}
                x2={1055}
                y2={1180}
                x3={1185}
                y3={1220}
                x4={1170}
                y4={1225}
                x5={1030}
                y5={1175}
                x6={1005}
                y6={1230}
              />
              <TrollTireSwing />
              <Sprite
                interactive
                pointerdown={handletwitterClick}
                image={TwitterMobileImage}
                x={1550}
                y={100}
              />
              <Sprite
                interactive
                pointerdown={handleTelegramClick}
                image={TelegramMobilImage}
                x={1800}
                y={50}
              />
            </Sprite>
          </Stage>
        ) : (
          <Stage
            width={width}
            height={height}
            options={{ backgroundAlpha: 0, antialias: true }}
            className={styles.mobile_canvas}
            onMount={handleStageMount}
          >
            <Sprite image={SkyMobileImage} width={width} height={height}>
              <Sprite image={GroundMobileImage} x={0} y={3315} />
              <Sprite
                interactive
                pointerdown={handletwitterClick}
                image={TwitterMobileImage}
                x={550}
                y={100}
              />
              <Sprite
                interactive
                pointerdown={handleTelegramClick}
                image={TelegramMobilImage}
                x={750}
                y={50}
              />
              <Sprite image={BuildingMobileImage} x={0} y={100} />
              <Billboard1
                x1={0}
                y1={675}
                x3={0}
                y3={0}
                rotation={0}
                style={{ fontSize: "40px", wordWrap: true, wordWrapWidth: 650 }}
                image={BillBoardMobile1Image}
                isMobile={true}
              />
              <Billboard2
                x1={0}
                y1={1800}
                x2={0}
                y2={0}
                image={BillboardMobile2Image}
                isMobile={true}
              />
              <Sprite image={TrollUmbrellaMobileImage} x={0} y={3915} />
              <Sprite image={GrassMobileImage} x={0} y={4178} />
              <Sprite
                interactive
                pointerdown={handleMintClick}
                image={TrollMintMobileImage}
                x={570}
                y={3470}
              />
              <Sprite image={BuyMobileImage} x={625} y={3500} />
              <Moon x={950} y={3090} image={MoonMobileImage} />
              <Rocket
                xPosition={1050}
                yPostion={3640}
                image={RocketMobileImage}
              />
            </Sprite>
          </Stage>
        )}
      </div>
      <StroySection initialLoad={initialLoad} />
    </div>
  );
};

export default App;
