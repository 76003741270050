import { useTick } from "@inlet/react-pixi";
import { useState } from "react";

export default function useLineAnimationTick(func, limit = 100) {
  const [it, setIt] = useState(0);

  useTick((delta) => {
    let temp = it + delta;
    if (temp > limit) {
      temp = delta;
    }
    func(temp);
    setIt(temp);
  });
}
